import React from 'react'
import Nav from '../components/Navbar'

const PatientCredit = () => {
  return (
    <div>
        <Nav/>
        PatientCredit
    </div>
  )
}

export default PatientCredit