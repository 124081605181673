import React from 'react'
import Nav from "../components/Navbar"
import styled from 'styled-components'

const Dashboard = () => {
  return (
    <div>
        <Nav/>
         Dashboard
    </div>
  )
}

export default Dashboard